import axios from 'axios'

const AdminService = {
  async init () {
    return axios({
      url: axios.defaults.baseURL + 'admin/init',
      method: 'GET'
    })
  },

  async getUsers (filters) {
    return axios({
      url: axios.defaults.baseURL + 'user/getUsers',
      method: 'POST',
      data: JSON.stringify({ filters: filters })
    })
  },

  async getUser (id) {
    return axios({
      url: axios.defaults.baseURL + 'user/getUsers',
      method: 'POST',
      data: JSON.stringify({ filters: { id: +id, groupby: 'u.id' } })
    })
  },

  async saveUser (data) {
    return axios({
      url: axios.defaults.baseURL + 'user/save',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async removeUser (data) {
    return axios({
      url: axios.defaults.baseURL + 'user/remove',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async updateEmployee (data) {
    return axios({
      url: axios.defaults.baseURL + 'admin/updateEmployee',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async resetPassword (id) {
    return axios({
      url: axios.defaults.baseURL + 'admin/resetpassword/user/' + id,
      method: 'GET'
    })
  },

  async getCompanies () {
    const companies = []
    await axios({
      url: axios.defaults.baseURL + 'admin/getallcompanies/',
      method: 'GET'
    }).then(
      (response) => {
        if (response && response.data && response.data.info && response.data.info.length) {
          response.data.info.forEach((r) => {
            companies.push({ id: r.id, name: r.name, organization_id: r.organization_id, isBookkeeper: true, calendar: r.calendar, unread: r.unread, bookkeeper_id: r.bookkeeper_id })
          })
        }
      }
    )
    return companies.sort((a, b) => ('' + a.name).localeCompare(b.name))
  },

  async getCompaniesForClient () {
    const companies = []
    await axios({
      url: axios.defaults.baseURL + 'admin/getallcompaniesclient/',
      method: 'GET'
    }).then(
      (response) => {
        if (response && response.data && response.data.info && response.data.info.length) {
          response.data.info.forEach((r) => {
            companies.push({ id: r.id, name: r.name, organization_id: r.organization_id, isBookkeeper: true, unread: r.unread, bookkeeper_id: r.bookkeeper_id })
          })
        }
      }
    )
    return companies.sort((a, b) => ('' + a.name).localeCompare(b.name))
  },

  async getFeedbackCompanies () {
    const companies = []
    await axios({
      url: axios.defaults.baseURL + 'admin/getallcompanies/?from=feedback',
      method: 'GET'
    }).then(
      (response) => {
        if (response && response.data && response.data.info && response.data.info.length) {
          response.data.info.forEach((r) => {
            companies.push({ id: r.id, name: r.name, organization_id: r.organization_id, isBookkeeper: true, calendar: r.calendar, unread: r.unread, bookkeeper_id: r.bookkeeper_id })
          })
        }
      }
    )
    return companies.sort((a, b) => ('' + a.name).localeCompare(b.name))
  },

  async getTeams () {
    return axios({
      url: axios.defaults.baseURL + 'admin/getteams/',
      method: 'GET'
    })
  },

  async getBookkeepers () {
    return axios({
      url: axios.defaults.baseURL + 'admin/getallbookkeepers/',
      method: 'GET'
    })
  },

  // New methods for managing user company access

  async getUserCompanies (userId) {
    return axios({
      url: axios.defaults.baseURL + 'admin/user/' + userId + '/companies',
      method: 'GET'
    })
  },

  async getOrganizationCompanies (userId) {
    return axios({
      url: axios.defaults.baseURL + 'admin/user/' + userId + '/organizations',
      method: 'GET'
    })
  },

  async addUserCompanyAccess (accessData) {
    return axios({
      url: axios.defaults.baseURL + 'admin/user/companies',
      method: 'POST',
      data: JSON.stringify(accessData)
    })
  },

  async removeUserCompanyAccess (userRoleId) {
    return axios({
      url: axios.defaults.baseURL + 'admin/user/companies/' + userRoleId,
      method: 'DELETE'
    })
  }
}

export default AdminService
